import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"id":"user-login","fluid":"","tag":"section"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('base-material-card',{attrs:{"icon":"mdi-login"}},[_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" Log in to your account ")]),_c(VForm,{ref:"loginForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,{staticClass:"py-0"},[_c('div',{attrs:{"data-cy":"login-error"}},_vm._l((_vm.errors),function(error,i){return _c(VAlert,{key:i,attrs:{"type":"error","outlined":""}},[_vm._v(" "+_vm._s(error)+" ")])}),1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextField,{attrs:{"label":"Username","id":_vm.username,"type":"email","rules":_vm.rules.username,"validate-on-blur":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextField,{attrs:{"label":"Password","id":_vm.password,"type":"password","rules":_vm.rules.password},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VBtn,{staticClass:"mr-0",attrs:{"color":"success","type":"submit","data-cy":"login-button"}},[_vm._v(" Login ")])],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c('p',[_vm._v(" Trouble logging in? "),_c('router-link',{attrs:{"to":{name: 'reset_password'}}},[_vm._v("Click here to reset your password.")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }